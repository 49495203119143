<template>
  <div class="app-container">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="经营主体：">
        <el-select v-model="formInline.data.ownCompanyId" placeholder="活动区域">
          <el-option label="全部" value=""></el-option>
          <el-option :label="item.label" :value="item.value" v-for="(item, index) in ownCompanyInfoList" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      element-loading-text="加载中"
      fit
      highlight-current-row
      ref="multipleTable"
      v-loading.body="listLoading"
    >
      <el-table-column align="center" label="金额">
        <template slot-scope="{ row }">
          <span>
            ¥{{ formaNumber(row.withdrawAmount || 0, { type: 'thousands' }) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="退款主体">
        <template slot-scope="{ row }">
          <span>
            {{ row.merchantTypeName }}-{{ row.contractInfo || row.contactRealName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账户类型">
        <template slot-scope="{ row }">
          <span>{{ row.withdrawAccountName || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="退款账户">
        <template slot-scope="{ row }">
          {{ row.merchantType === 'ENTERPRISE' ? row.contractInfo : row.contactRealName }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="经营主体">
        <template slot-scope="{ row }">
          <span>{{ row.ownCompanyName || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="退款账号" prop="cardNumber"></el-table-column>
      <el-table-column align="center" label="变动时间">
        <template slot-scope="{ row }">{{ row.applyDate | parseDefaultTime() }}</template>
      </el-table-column>

      <el-table-column align="center" label="状态">
        <template slot-scope="{ row }">{{ row.statusName }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="formInline.pageNo"
        :disabled="listLoading"
        :page-size="formInline.pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { distributorWithdrawRecordList, listOwnCompanyInfo } from '@/api/shop';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { formaNumber } from '@/utils/date';
export default {
  name: 'cash-out-list',
  components: {},
  data() {
    return {
      formaNumber,
      total: 0,
      list: [],
      listLoading: false,
      data: {},
      ownCompanyInfoList: [],
      formInline: {
        data: {
          ownCompanyId: '',
        },
        pageNo: 1,
        pageSize: 10,
      }
    };
  },
  mounted() {
    const { ownCompanyId } = this.$route.query;
    this.formInline.data.ownCompanyId = ownCompanyId || '';
    this.fetchOwnCompanyInfo();
    this.onSearch();
  },
  methods: {
    onReset() {
      this.formInline = cloneDeep({
        data: {
          ownCompanyId: ''
        },
        pageNo: 1,
        pageSize: 10,
      });
      this.onSearch();
    },
    onSearch() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this.formInline, ['pageNo', 'pageSize', 'data']);
      distributorWithdrawRecordList(listQuery)
        .then(response => {
          const { list = [], total = 0 } = response.data || {};
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取经营主体
    fetchOwnCompanyInfo() {
      listOwnCompanyInfo()
        .then(response => {
          const data = response.data || {};
          this.ownCompanyInfoList = data.map(item => {
            return {
              value: item.ownCompanyId,
              label: item.ownCompanyName
            };
          });
        });
    },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      this.formInline.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}
.cont-title {
  padding-left: 20px;
  font-size: 14px;
  margin-bottom: 20px;
  color: #a9a9a9;
}
</style>